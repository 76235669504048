@import '../../Styles/package';


.projects {
    display: flex;
    flex-direction: column;
    justify-content: center; 
    align-items: center;
    
    &__header {
        // border: 1px solid red;
        // border: 1px solid red;
        // height: 100%;
        padding: 50px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 500px;
        @include breakpoint(mobile) {
            width: 100%; 
            padding: 50px 10px 0 10px;
        }

    }


    &__title {
        font-size: 7em;
        font-weight: bold;
        color: #222;
        
        overflow: hidden;
        
        position: relative;
        z-index: 10;
        
        
        .title-text {
            z-index: 11;
            // border: 1px solid red;
            animation: titlePopDown 1s ease-in-out;
            @include textOutline(#fff);
            @include breakpoint(mobile) {
                font-size: .8em;
                // border: 1px solid red;
            }

            &.away {
                animation: titlePopUp 1s ease-in-out;
                animation-fill-mode: both;
            }
        }
        
    }

    &__list {
        display: flex; 
        justify-content: center;
        // border: 1px solid red;
        width: 500px;
        animation: projectListStart 1s ease-in-out;
        animation-delay: 1s;
        animation-fill-mode: both;


        @include breakpoint(mobile) {
            width: 100%; 
            padding: 0 10px;
        }

        &.away {
            animation: projectListFinish .8s ease-in-out;
            animation-fill-mode: both;
        }
    
    }
}


.bar {
    z-index: 12;
    width: 100%;
    height: 5px;
    border-radius: 5px;
    margin: 2px 0;
    background: $gradient;
    // &--blue {background-color: $blue}
    // &--green {background-color: $green}
    // &--red {background-color: $red}
    // &--yellow {background-color: $yellow}
}