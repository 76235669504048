@import '../../Styles/package';



.home-header {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}


.section {

    &--main {
        margin-bottom: 50px;    
    }
}
