

@import '../../Styles/package';



.navigation {
    // border: 1px solid red;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // border: 1px solid blue;
    width: 500px;
    @include breakpoint(mobile) {width: 300px;}


    &__links {
        position: relative;
        // border: 1px solid red;
        height: auto;
        // width: 90%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
        padding: 20px 0px;
        width: 100%


    }

    &__link {
        // text-align: right;
        position: relative;
        cursor: pointer;
        font-size: 4em;
        font-weight: bold;
        // border: 1px solid red;
        color: #fff;
        z-index: 10;
        margin-bottom: 20px;
        
        @include breakpoint(mobile) {font-size: 2em;}


        &:last-child {margin-bottom: 0px;}
        &::after {
            content: '';
            position: absolute;
            width: 100%;
            height:10px;
            
            transform: scaleX(0);
            background: $gradient;
            bottom:0;
            left: 0;
            transform-origin: bottom right;
            transition: transform 0.25s ease-out;
            z-index: -1;
            // border-radius: 10px;
           
        }
        &:hover::after {
            transform: scaleX(1);
            transform-origin: bottom left;
        }
     
    }



    .corner {
        position: absolute;
        height: 100px;
        width: 100px;
        display:none;

        background: $gradient;
        


        &--left {
            left: 0;
            top: 0;
            clip-path: polygon(0% 0%, 0% 100%, 10% 100%, 10% 10%, 100% 10%, 100% 0%);
        }
        &--right {
            right: 0;
            bottom: 0;
            clip-path: polygon(90% 0, 90% 90%, 0 90%, 0 100%, 100% 100%, 100% 0%);

        }

        

    }



}