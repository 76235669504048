@import '../../../Styles/package';


.caution-sign {
    background-color: $yellow;
    height: 200px; width: 400px;
    margin-top: 50px;
    border-radius: 10px;
    padding: 10px;
    box-shadow: 0px 7px 10px rgba(black, 0.5);
    cursor: not-allowed;
    .top-row {
            color: $yellow;
            text-align: center;
            font-size: 2em;
            font-weight: bold;
            text-transform: uppercase;
            background-color: #222;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
    }
    .bottom-row {
        font-size: 2em;
        text-align: center;
        font-weight: bold;
        margin-top: 20px;
        text-transform: capitalize;
    }

}