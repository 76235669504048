

@import '../../../Styles/package';



.project-list {
    // border: 1px solid red;
    width: 500px;
  
    &__project {
        position: relative;
        cursor: pointer;
        font-size: 1.5em;
        // border: 1px solid red;
        
        font-weight: bold;
        z-index: 10;
        padding: 10px 10px;
        line-height: 1em;

        &:hover {
            background-color: $blue;
            color: #fff;
        }
        // &::after {
        //     content: '';
        //     position: absolute;
        //     width: 100%;
        //     height:10px;
            
        //     transform: scaleX(0);
        //     background: $gradient;
        //     bottom:-5px;
        //     left: 0;
        //     transform-origin: bottom right;
        //     transition: transform 0.25s ease-out;
        //     z-index: -1;
        //     border-radius: 10px;
           
        // }
        // &:hover::after {
        //     transform: scaleX(1);
        //     transform-origin: bottom left;
        // }
    }

}


.icon-list {
    // border-top: 1px solid #eee;
    width: 100%;
    padding: 10px;
    // border: 1px solid red;
    
    // grid-template-columns: repeat(auto-fill, minmax(25%,1fr));
    // grid-gap: 1rem;
    

}

