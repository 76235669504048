
@import '../Styles/package';




.page__layout {
    height: 100vh;
    width: 100%;
    position: relative;
}

.backdrop {
    z-index: -1;
    background-color: #222;
    position: fixed;
    top: 0; left: 0;
    height: 100vh; width: 100vw;
    // border: 1px solid red;

}