




$blue: #6cb8ea;
$red: #ff5959;
$green: #29d0be;
$yellow:#e1f549;









$gradient: linear-gradient(110deg,#e1f549,#29d0be,#6cb8ea,#ff5959);




$card-shadow: 0 1px 3px 0 rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 2px 1px -1px rgba(0,0,0,.12);