


@keyframes iconPopUp {
    0%{
        transform: translateY(150%);
        opacity: 0;;
    
    }
    100%{ 
        transform: translateY(0);
        opacity: 1;
    }   
}


@keyframes fadeIn {
    0%{opacity: 0;}
    100%{opacity: 1;}
}





@keyframes projectListStart {
    0%{
        transform: translateY(10%);
        opacity: 0;
    
    }
    100%{ 
        transform: translateY(0);
        opacity: 1;
    }  
}

@keyframes projectListFinish {
    0%{
        transform: translateY(0);
        opacity: 1;
    
    }
    100%{ 
        transform: translateY(10%);
        opacity: 0;
    }  
}

@keyframes titlePopDown {
    0%{
        transform: translateY(-150%);
        
    
    }
    100%{ 
        transform: translateY(0);
        
    }  
}


@keyframes titlePopUp {
    
    0%{
        transform: translateY(0);
    }
    100%{ 
        transform: translateY(-150%);
    }  
}