@import '../../../Styles/package';



.project-info {

    &__row {
        margin: 40px 0 ;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__role {
        // border: 1px solid red;


        .role {
            &__title {
                font-size: 1.5em;
            }
            &__info {
                font-weight: bold;
                font-size: 1.5em;;
            }
        }
    }
    
    &__site {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;


        i {
            margin-bottom: 10px;
            font-size: 2em;
        }
        
    }


}