





@import '../package';




.header__title {
    position: absolute;
    left: 10px;
    top: 0;
    transform-origin: top left;
    // border: 1px solid red;
    color: #222;
    font-size: 9em;
    font-weight: bold;
    // transform: rotate(-90deg);
    animation: fadeIn 1s ease-in;
    @include textOutline(#fff);
}


.btn {cursor: pointer;}


.grad-bar  {
    width: 100%;
    height: 5px;
    border-radius: 5px;
    margin: 10px 0;
    background: $gradient;
    // &--blue {background-color: $blue}
    // &--green {background-color: $green}
    // &--red {background-color: $red}
    // &--yellow {background-color: $yellow}
}


.button--underline {
    position: relative;
    cursor: pointer;

    font-weight: bold;
    // border: 1px solid red;
    color: #fff;
    z-index: 10;
    margin-bottom: 20px;
    
    &:last-child {margin-bottom: 0px;}
    &::after {
        content: '';
        position: absolute;
        width: 100%;
        height:10px;
        transform: scaleX(0);
        background: $gradient;
        bottom:0;
        left: 0;
        transform-origin: bottom right;
        transition: transform 0.25s ease-out;
        z-index: -1;
        // border-radius: 10px;
       
    }
    &:hover::after {
        transform: scaleX(1);
        transform-origin: bottom left;
    }
}