

*{
    box-sizing: border-box
}

body {
    box-sizing: inherit;
    p {margin: 0;}
    font-family: neue-haas-grotesk-display, sans-serif;
    font-style: normal;
}


a {
    text-decoration: none;
    color: inherit;
}

.read-text { font-family: neue-haas-grotesk-text, sans-serif; }


@import './utils';
@import '../abstracts/animations';



