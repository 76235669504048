

@import '../../../Styles/package';



.project__images {


    padding: 100px 0;  
    display: flex;
    // align-items: center;
    justify-content: center;


    &--list {
        // cursor: zoom-in;
        transition: width .2s ease-in-out;
        height: auto;
        width: 100%;;
        // border: 1px solid red;
        border-radius: 10px;
        background-color: #eee;
        box-shadow: $card-shadow;
    
        display: flex;
        flex-direction: column;
        img {
            width: 100%;

        }

        &:hover {width: 100%}

        @include breakpoint(mobile) {
            width: 100%;
        }
    }
}